#posts {
  padding: 0;
}

#post:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

#site {
  background:#212121;
  width: 99vw;
}

#category-button-icon {
  font-size: 2vh;
  position: absolute;
  bottom: 20%;
}

#category-buttons {
  margin: auto;
  width: 40vw;
  position: relative;
  top: 3px;
}

#categoryButton1,
#categoryButton2,
#categoryButton3 {
  position: relative;
  border: none;
  background: var(--primary-color);
  height: 3.5vh;
}

#categoryButton1 {
  border-radius: 4px 0 0 0;
  width: 33%;
}

#categoryButton2 {
  width: 34%;
}

#categoryButton3 {
  border-radius: 0 4px 0 0;
  width: 33%;
}

#categoryButton1:hover,
#categoryButton2:hover,
#categoryButton3:hover {
  background: var(--primary-color-hover);
  cursor: pointer;
}

#new-post {
  position: relative;
  left: 78.6%;
  border: 0.5vh solid var(--primary-color);
  background-color: #2f2f2f; /* Added a background color for better contrast */
  color: var(--primary-color);
  font-size: 1.8vh; /* Changed from vh to rem for better scalability */
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease; /* Added transition for transform */
  padding: 0.5vh 0.9vh; /* Changed to em for more consistent padding */
  margin-top: 0.5%;
  width: 18.4vh;
}

#new-post:hover {
  background: #e9e9e9e0;
  transform: translateY(-2px); /* Added a subtle lift on hover */
}

#search-user:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

#search-wrapper {
  position: relative;
  height: fit-content;
  width: fit-content; 
  left: 77.7%;
}

#search-user {
  color: black;
  border: none;
  border-radius: 20px;
  padding: 1vh 1vh;
  font-size: 1.6vh;
  background-color: #f0f0f0;
  outline: none;
  width: fit-content;
}

#search-user::placeholder {
  color: #999;
}

#search-button {
  position: relative;
  right: 15%;
  top: 50%;
  border: none;
  border-radius: 0 20px 20px 0;
  background: none;
  cursor: pointer;
}

#picture-switch-buttons-area{
 margin-top: 2%;
}

#search-button-icon{
  font-size: 1.8vh;
}

#pollchart-area{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 0.5vh solid var(--primary-color);
  border-radius: 25px;
  padding: 1vh;
  width: 35vw;
  background-color: #f0f0f0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}