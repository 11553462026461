#logoButton {
  border: none;
  background: none;
  font-size: 5vh;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin-right: 1%;
  margin-left: 1%;
  color: var(--primary-color);
}

#logoButton:hover {
  cursor: pointer;
}

#cloud-border{
  position: absolute;
  z-index: 10;
  left: 65vw;
  font-size: 1.7vh;
  top: "2px";
}

#login-button {
  position: relative;
  left: 80vw;
  top: 3vh;
  z-index: 1;
  border: none;
  background-color: transparent;
  font-size: 1.6vh;
  color: var(--primary-color);
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#login-button:hover {
  cursor: pointer;
  color: blue;
}


#popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 1000; /* higher than other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

#qr-code-area {
  width: 50%;
  height: 28%;
  background: white;
  border: 3px solid black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
}

#qr-code-text {
  margin-bottom: 20px;
  width: 50%;
  margin: auto;
  margin-bottom: 3%;
}

#qr-code {
  width: 200px; /* adjust size as needed */
  margin-bottom: 15px;
}

#qr-code-cancel{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #70a7c7;
}

#qr-code-cancel:hover{
  cursor: pointer;
}

#profile-id-nr-header{
  font-size: 2.2vh;
  color: var(--primary-color);
}

#profile-id-header{
  font-size: 1.8vh;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  position: absolute;
  left: 5%;
  top: 2%;
  color: var(--primary-color);
}