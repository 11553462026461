#post-with-id {
  position: relative;
  margin: auto;
  width: 45%;
  height: fit-content;
  min-height: 25vh;
  background-color: #2f2f2f;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.1s ease-in-out;
  border-top: 1.2vh solid var(--primary-color);
  padding: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#sort-filter {
  border: none;
  background-color: transparent;
  color: var(--primary-color);
  position: relative;
  left: 30vw;
  font-size: 1.5vh;
}

#sort-filter:hover {
  color: black;
  cursor: pointer;
}

#add-comment-area {
  width: 40vw;
  height: 10vh;
  margin: auto;
  margin-top: 2vh;
  margin-bottom: 0.5vh;
  display: flex;
  
}

#add-comment-input {
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  width: 30vw;
  height: 7vh;
  resize: none;
  outline: none;
  font-size: 2vh;
  background-color: #2f2f2f;
  padding: 0.7vw 0.7vh;
  color: #ebebeb;
}

#image-upload-area {
  width: 40vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the start of the container */
}

#add-comment-button {
  font-size: 3vh;
  text-align: center;
  border: none;
  border-radius: 1vh;
  background-color:   #2f2f2f;
  color: #fffafa;
  border: 2px solid var(--primary-color);
  margin-left: 1.7vw;
  margin-top: 1.6vh;
  height: 7vh;
  width: 7vh;
}

#add-comment-button:hover {
  background: #e9e9e9e0;
  transform: translateY(-2px); /* Added a subtle lift on hover */
  cursor: pointer;
}

#create-post-button:hover {   
  background: #e9e9e9e0;
  transform: translateY(-2px); /* Added a subtle lift on hover */
}

#chart-area-button {
  position: relative;
  width: 11.5%;
  margin-top: 1%;
  margin: auto;
}

#vote-on-post {
  width: 6vw;
  cursor: pointer;
  margin-top: 10%;
  margin-bottom: 10%;
  padding: 1.5% 2%;
  font-size: 100%;
  text-align: center;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  color: black;
  position: relative;
  height: 4vh;
}

#vote-on-post:hover{
  transform: translateY(-2px);
}

#comments + #comments {
  margin-top: 2vh;
}

.upvote-btn,
.downvote-btn {
  border: none;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: transparent;
  color: #ebebeb;
  font-size: 1.5vh;
}

.upvote-btn:hover,
.downvote-btn:hover {
  color: var(--primary-color);
}

#picture-switch-buttons {
  border: none;
}

#picture-switch-buttons:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.custom-button:hover {
  color: black;
  cursor: pointer;
}

#post-vote {
  display: flex;
  justify-content: flex-end; /* Aligns its children to the right */
}

.custom-button-post{
  color: var(--primary-color);
}

#vote-icons{
  
}
