.container {
  width: 70%;
  margin: auto;
  display: flex;
  height: 100%;
}

.content {
  width: 65%;
  height: 100vh;
}

.sidebar {
  width: 30%;
  margin-left: 20px;
  margin-top: 4vh;
}

.pretty-slider-checkbox {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.slider {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  transition: background-color 0.4s;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#slider-label{
  color: var(--primary-color);
}

.slider-circle {
  position: absolute;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.4s;
  top: 2px;
}

.slider input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.checked {
  background-color: var(--primary-color);
}

.slider.checked .slider-circle {
  transform: translateX(30px);
}

.pretty-slider-checkbox p {
  margin-left: 10px;
  color: #333;
  font-weight: 500;
}

.vote-input {
  color: black;
  border: none;
  border-radius: 20px;
  padding: 1vh 1vh;
  font-size: 1.7vh;
  background-color: #f0f0f0;
  outline: none;
  width: 90%; 
  margin-bottom: 2%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#vote-buttons-container {
  justify-content: space-between;
}

.vote-label {
  border: 2px solid var(--primary-color);
  background-color: #2f2f2f;
  margin-bottom: 5px;
  padding: 0px 10px 2px 10px;
  font-size: 2vh;
  text-align: center;
  border-radius: 20px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vote-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-weight: 500;
  margin-left: 10px;
  color: var(--primary-text-color);
}

.remove-button {
  border: none;
  background: transparent;
  color: #ff5e5e;
  font-size: 1.2em;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  transition: transform 0.2s ease;
}

.remove-button:hover {
  transform: scale(1.1);
}


#vote-buttons:hover {
  background-color: #faa6a68e;
  transform: scale(1.02);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#post-creation-message {
  position: fixed;
  bottom: 20px;
  left: 40vw;
  transform: translateX(-50%);
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  animation: fade-in-out 5s ease-in-out forwards;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

#create-post-button {
  border: 2px solid var(--primary-color);
  background-color: #2f2f2f;
  color: var(--primary-color);
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  padding: 0.5em 1em;
  position: relative;
  margin-top: 5px;
}

#create-post-button:hover {
  background: #e9e9e9e0;
  transform: translateY(-2px);
}



#new-post-images {
  background-color: var(--primary-color);
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  margin-right: 10px;
  margin-bottom: 10px;
  width: fit-content;
}

#new-post-images:hover {
  background-color: #ddd;
  color: #000;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  width: fit-content;
}

#new-post-images-area {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: fit-content;
  left: 10px;
}

#newpost_textarea {
  margin: 20px auto;
  margin-top: 7%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  background-color: #2f2f2f;
}

#newpost_text {
  width: 100%;
  height: 60vh;
  resize: none;
  border: none;
  outline: none;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  background-color: transparent;

}

#newpost_text::placeholder {
  color: #aaa;
}

.custom-button-label {
  cursor: pointer;
}

.custom-button{
  color: var(--primary-color);
}
  