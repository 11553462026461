#siteHeader {
  font-size: 80px;
  font-family: "Courier New";
  margin-right: 20px;
  margin-left: 25px;
  flex: 0;
}

#post {
  border: none;
  border-radius: 0 0 5px 5px;
  margin-bottom: 25px;
  height: 15vh;
  word-wrap: break-word;
  background: #e0e0e0;
  padding: 20px;
  box-shadow: 1px 2px 1px #a9d7e9;
  width: 850px;
}

#postContent {
  margin-left: 5vh;
}

#site {
  min-height: 100vh;
  background: #ffffff;
}

#siteHeader2 {
  font-size: 20px;
  font-style: oblique;
  margin-right: 15px;
  margin-left: 15px;
  white-space: normal;
}

#categoryButton1 {
  border: none;
  border-bottom: 1px solid black;
  background: #68a7cc;
  width: 300px;
  border-radius: 20px 0 0 0;
}
#categoryButton2 {
  border: none;
  border-bottom: 1px solid black;
  background: #68a7cc;
  width: 250px;
}
#categoryButton3 {
  border: none;
  border-bottom: 1px solid black;
  background: #68a7cc;
  width: 300px;
  border-radius: 0 20px 0 0;
}

#categoryButton1:hover,
#categoryButton2:hover,
#categoryButton3:hover {
  cursor: pointer;
}


:root {
  --primary-color: #387478;
  --primary-color-hover: #3b8186;
  --primary-text-color: #e4e6eb;
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height for vertical centering */
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
