#vote-popup {
  background-color: #fff;
  border: 1px solid darkgrey;
  width: 50vw;
  height: fit-content;
  max-height: 80vh;
  box-shadow: 2px 2px 10px;
  position: relative;
  z-index: 10;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  border: 3px solid black;
  padding: 20px;
  border-radius: 10px;
}

#vote-button {
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 100%;
  text-align: center;
  border: 2px solid #70a7c7;
  border-radius: 5px;
  color: black;
  position: relative;
  left: 20%;
  width: 60%;
  padding-top: 1%;
  padding-bottom: 1%;
}

#vote-button:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

#vote-area{
  position: relative;
  margin: auto;
  width: 47%;
  height: fit-content;
  min-height: 25vh;
  border-radius: 0 0 2% 2%;
  background-color: #ececec;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.1s ease-in-out;
  border-top: 1.2vh solid #74b3d8;
}