#profile-id{
    font-size: 1.5vh;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color: var(--primary-color); 
}

#profile-id:first-child {
    margin-top: 5%;
}

#profile-id:not(:first-child) {
    margin-top: 0;
}

#profile-id-border{
    position: relative;
    left: 18%;
    border: 1px solid var(--primary-color);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    width: fit-content;
    height: fit-content;
    border-radius: 2px;
    padding: 0.5% 1%;
    background-color: white;
    border-radius: 3px;
    background-color: #2f2f2f;
}

#profile-id-nr{
 font-size: 2vh;
 color: var(--primary-color);
}