  #post {
    position: relative;
    margin: auto;
    width: 45%;
    height: fit-content;
    border-radius: 0 0 0 0;
    background-color: #2f2f2f;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s ease-in-out;
    border-top: 2vh solid var(--primary-color);
  }
  
  #post + #post {
    margin-top: 5vh;
  }
  
  .post-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .post-header {
    position: relative;
    display: flex;
    left: 5%;
  }

  .post-header:hover {
    cursor:pointer
  }
  
  .post-content {
    display: flex;
    font-size: 1.8vh;
    word-break: break-all;
    width: 80%;
    margin: 0 auto;
    margin-top: 2%;
    min-height: 30vh;
    white-space: pre-line;
  }
  
  .image-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  #post-picture-area {
    justify-content: center;
    margin-top: 1%;
    border: 2px solid black;
    border-radius: 10px;
    margin-bottom: 3%;
  }
  
  #picture-switch-buttons {
    font-size: 1.5vh;
    background: none;
  }
  
  .post-footer {
    position: relative;
    margin-top: 2%;
    bottom: 1px;
    right: 4%;
  }
  
  #post-votes,
  #post-date,
  #post-userid{
    font-size: 1.5vh;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-align: right;
    color: #e4e6eb;
  }
  
  #line {
    margin-top: 0.5%;
    border-bottom: 0.1vh solid rgba(205, 206, 207,0.8);
    width: 92%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
    align-self: center;
  }
  
  #line-for-images {
    margin-top: 2%;
    width: 85%;
    height: 0.1vh;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
  

  p{
    color: #e4e6eb;
    font-weight: 700;
    white-space: pre; /* Preserves spaces, tabs, and line breaks */
    font-family: monospace;
  }